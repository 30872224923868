#activityGird {
    flex:1;
    padding: 0;
    margin: 0;
	display: flex;
    flex-flow: column;
    min-height:0
}

.HeaderRow {
    padding-bottom: 0px!important;
    padding-top: 0px!important;
}

.WorkspaceRow {
	padding-top: 0px!important;
	padding-bottom: 0px!important;
}

#CourseBlocklyComponent{
	width: 100%;
	height: 100%;
}



.message{
    opacity: 1;
    transition: opacity 700ms ease-in;
    border: 1px solid white;
    border-radius: 10px;

    margin-bottom: 15px;
    margin-left: 5px;
    margin-right: 5px;

    padding: 10px;

    background-color: white;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.message-img-container{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    width: 70px;
    height: 70px;
}

.message-body{
    width: 85%;
    margin-left: 10px;
}

.message-body-header{
    min-height: 20px;

    padding-top: 2px;
    margin-bottom: 10px;
}

.message-body-header-author{
    font-weight: 700;
    font-size: 15px;

    margin-bottom: 5px;
}

.message-body-header-title{
    color: #717274;
}

.message-body-content-image{
    position: relative;
    max-height: 120px;
    border-radius: 10px;
}

.message-body-content-link {
    word-break: break-word;
}

.consigne{
    font-weight: 700;
}


.main{
    background-image: url("../../img/miss.svg");
}

.doc{
    background-image: url("../../img/doc.svg");
}

.tech{
    background-image: url("../../img/technicien.svg");
}

.message.message-appear {
    opacity: 0.01;
}

.message.message-appear.message-appear-active {
    opacity: 0.01;
}
    
.message-enter {
    opacity: 0.01;
}

.message-enter.message-enter-active {
    opacity: 1;
    transition: opacity 700ms ease-in;
}

.message-leave {
    opacity: 1;
}

.message-leave.message-leave-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
}