#HeaderFreeCreationControlsCard{
	padding: 10px 20px;
	margin:0;
}

.HeaderFreeCreationStatusText{
	text-decoration: underline;
	font-size:0.85em;
	text-align: center;
	margin-top: 2px;
}

.loggedFreeCreationControlsRow{
	/* padding-bottom: 0px!important; */
}

.workspaceControlsRow{
	padding-top: 0px!important;
}

.workspaceButtonGroup {
	/* margin-top: 5px!important; */
}