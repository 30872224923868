.profil-bandeau-container{
    min-height: 170px;

    background-image: url("../../img/banniere_simple_thingz.svg");
    /* background-size: unset; */
    /* background-position-y: -100px; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

@media only screen and (max-width: 768px){
    .profil-bandeau-container{
        background-position: 0% 50%; 
    }
}

.profil-bandeau-row{
    padding-bottom: 0px!important;
}

.profil-bandeau-column{
    /* padding-left: 0px!important;
    padding-right: 0px!important; */
}

.profil-bandeau-card{
    margin-top: 50px;
    padding-top: 15px;
    

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.active.item {
    /* background-color: whitesmoke!important; */
}

.pane {
    /* background-color: whitesmoke; */
    padding: 1em;
    border-left: 1px solid rgba(34,36,38,.15);
    border-right: 1px solid rgba(34,36,38,.15);
    border-bottom: 1px solid rgba(34,36,38,.15);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.AppSummaryItem{
    background: white!important;
}

.userAvatarCircularBordered {
    /* border-radius: 50%; */
    background-image: url("../../img/smoke.png");
    border: 2px solid white;
    /* background-image: url("../../img/white.png");
    border: 2px solid rgb(212, 212, 213); */
}
.spreadsheetUrlForm input {
    margin: 15px 0 !important;
}
#spreadsheet_link {
    word-break: break-word;
}