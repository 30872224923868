.header-bottom {
    display: flex;
    justify-content: flex-start;

    min-height: 12vh;

    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

}

.header-bottom-container {
    padding-top: 10px;
    padding-bottom: 10px;

    padding-right: 20px;

    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    min-height: 90px;

    opacity: 1;
}

/*
.header-bottom-container-descriptionCourse:before {
    content: '';
    background-color: rgba(91, 235, 127, 0.4);
    width: 1000vw;
    height: 1000vh;
    margin-left: -50vw;
    left: 50%;
    opacity: 0.5;

    z-index: 5000;
    position: absolute;
}
*/
/*
.descriptionCourse-text {
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 5px;

    border-radius: 10px;

    transition: background-color 400ms ease-in;
}
*/

.descriptionCourse-text3 {
    font-size: 18px;

    color: #ffa403;
    transform: scale(1);
    transition: all 200ms linear;
}

.button-ok {
    margin-left: 20px;
}

.header-course-buttons {
    
    padding-left: 10px;
    padding-right: 10px;

    margin-right: 20px;
    margin-left: 40px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;

    justify-content: space-around;

}

.header-bottom-descriptionStep {
    border: 1px solid yellow;

    padding-top: 10px;
    padding-bottom: 10px;

    margin-bottom: 15px;
}

.header-bottom-button-nextStep {
    padding: 10px;
    min-width: 150px;
}

.header-bottom-buttonSendCard {
    padding: 10px;
    min-width: 150px;
    transition: all 200ms linear;
}

.header-bottom-buttonSendCard-loading {
    background-image: url("./bars.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;

    /*
    transition: all 200ms linear;
    */
    height: 42px;
    font-size: 0;
    cursor: progress;

}

.header-bottom-buttonVerification {
    height: 42px;
}

.button-indice {
    height: 42px;
}

#instructionHighLight {
    display: flex;

    align-items: center;
    justify-content: space-between;

    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;

    font-size: 20px;

    /* z-index: 2000; */

    border-radius: 10px;

    transition: all 200ms linear;

    position: relative;
    /* background-color: white; */
}

.button-avancement {
}

/*           EXEMPLE 2             */

.example-enter {
    opacity: 0.01;
    margin-top: -60px;
}

.example-enter.example-enter-active {
    opacity: 1;
    margin-top: 0;
    transition: opacity, margin-top 500ms linear;
}

.example-leave {
    opacity: 1;
    margin-top: 0;
}

.example-leave.example-leave-active {
    opacity: 0;
    margin-top: 60px;
    transition: opacity, margin-top 500ms linear;
}

/*           EXEMPLE 2             */
.fade-enter {
    background-color: rgba(235, 16, 16, 0.13);
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    background-color: rgba(235, 16, 16, 0.4);
    transition: opacity, background-color 400ms ease-in;
}

.fade-leave {
    opacity: 0.1;
}

.fade-leave.fade-leave-active {
    opacity: 0;
    display: none;
    transition: opacity 300ms linear;
}

/* EXEMPLE 3  */

.fade2-enter {
    opacity: 0.01;
}

.fade2-enter.fade2-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
}

.fade2-leave {
    opacity: 0.1;
}

.fade2-leave.fade2-leave-active {
    opacity: 0;
    display: none;
    transition: opacity 300ms linear;
}

/* EXEMPLE 4  */

.fade3-enter {
    transform: scale(0.7);
    opacity: 0.01;
}

.fade3-enter.fade3-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 350ms linear;
}

.fade3-leave {
    opacity: 0;
}

.fade3-leave.fade3-leave-active {
    opacity: 0;
    display: none;
    transition: opacity 300ms linear;
}

/*
.element, .duration, .action, .categorie, .position, .location {
    font-weight: 600;
    font-size: 15px;
}


.element {
    color: green;
}

.action {
    color: orange;
}

.categorie {
    color: blue;
}

.duration {
    color: lightseagreen;
}

.location, .position {
    color: blueviolet;
}
*/