
.channel-container {
    background-color: rgba(122, 133, 138, 0.31);
    /*height: 100vh;*/
    height: 100%;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}

.messages-container {
    margin-bottom: 0px;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    min-height: 0;
    padding: 10px;
    height: 0px;

}

.button-container {
    border: 1px solid #717274;
    border-radius: 10px;

    margin-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;

    background-color: white;


    height: 6%;
    align-items: center;

}

.message-enter {
    opacity: 0.01;
}

.message-enter.message-enter-active {
    opacity: 1;
    transition: opacity 700ms ease-in;
}

.message-leave {
    opacity: 1;
}

.message-leave.message-leave-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
}