#main_grid {
    height: 100%;
	display: flex;
    flex-flow: column;
}

.HeaderRow {
	padding-bottom: 0px!important;
}

.WorkspaceRow {
	padding-top: 0px!important;
	padding-bottom: 0px!important;
}

#CourseBlocklyComponent{
	width: 100%;
	height: 100%;
}