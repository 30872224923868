.text-green {
    color: green
}
.text-yellow {
    color: yellow
}
.text-orange {
    color: orange
}
.text-red {
    color: red
}

/* h4{
    text-align: center;
} */