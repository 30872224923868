.field {
    display: inline;
    /* width: 20px; */
}

.spanBrickCount {
    float: right;
}

.brickColumn {
    /* margin: 0px; */
    border:none!important;
    box-shadow: none!important;
    padding: 0px!important;
}

.brickSegment {
    border:none!important;
    box-shadow: none!important;
    border-radius: 0px!important;
}

.bg-grey {
    background-color: lightgrey!important;
}