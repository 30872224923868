#FreeCreationBlocklyContainer{
	width: 100%;
	height: 100%;
	position : relative;
	z-index: 4;

	/* padding-top: 20px; */
}

#FreeAceComponent{

	height: 100%;
	width: 100%;
	padding: 0px;
	/* padding-top: 12px; */
	/* margin-right: 70px!important; */
	/* padding: auto !important */
	
}

#columnTarget{
	padding-right: 0px;
	/* padding: 0px; */
}

.innerRows{
	padding: 0px!important;
}

.FreeCreationRow{
	padding-bottom: 0px!important;
}

#freeCreationGrid{
	height: 100vh;
	display: flex;
  	flex-flow: column;
  	flex: 1;
}

#aceMainDiv{
	width:auto !important;
}

.FreeCreationRowAce{
	padding-bottom:0px!important;
}

#FreeAceComponentAlone{

	height: 100%;
	width: 75%!important;
	
}