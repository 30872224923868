#blocklyMain {
    position: relative;
    height: 100%;
    width: 100%;
}

/* modification position pour affichage du dropdown */
.blocklyToolboxDiv{
    height: 100%;
    min-width: 4%;
    transition: width 500ms linear;
    position: absolute;
    z-index: 1;

}

.blocklyFlyout{
    z-index: 1;
}

.blocklyScrollbarVertical{
    z-index: 1;
}

.blocklyWsDragSurface{ overflow: visible !important;}

.scratchCategoryMenuItemLabel{
    font-size: 1.2em; 
   
}