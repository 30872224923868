.dummyUHC {

    height: 44px;
    padding: 0px;
    margin: 0px;

    /* padding: .92857143em 1.14285714em;
    padding-top: 0.928571em;
    padding-right: 1.14286em;
    padding-bottom: 0.928571em;
    padding-left: 1.14286em; */
}