#HeaderFreeCreationMenu{
	margin: 0px!important;
	border: none!important;
}

.logo {
	padding-top: 0px!important;
	padding-bottom: 0px!important;
    /* padding-right: 0px!important; */
}
#spreadsheetUrl_link {
	font-size: 14px;
	font-weight: normal;
}
.notif_item {
	display: flex;
}
.notif_item_header {
	margin: 0 !important;
	padding: 0 !important;
}
#NotifPopup {
	width: 600px !important;
	max-height: 300px !important;
	overflow-y: scroll !important;
}