.segment_consultation {
    height: 100% !important;
    overflow: auto !important;
}

.segment_consultation p {
    font-size: 15px;
}

.segment_consultation p:first-child {
    margin-bottom: 5px;
}

.segment_consultation p:nth-child(4) {
    margin-top: 30px;
}

.date_activity {
    color: rgba(0,0,0,.4);
}

.btn_group {
    margin: 40px 0 !important;
}

.instruction {
    margin: 30px 0;
}

.message_consultation {
    display: block !important;
}

.message_consultation .header {
    margin-bottom: 12px !important;
}

