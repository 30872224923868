#blocklyMain {
    position: relative;
    height: 100%;
    width: 100%;
}

.blocklyToolboxDiv{
    height: 100%;
    min-width: 4%;
    transition: width 500ms linear;
}

.blocklyWsDragSurface{ overflow: visible !important;}

.scratchCategoryMenuItemLabel{
	font-size: 1.2em; 
}