.modal_toolbox {
    height: 95% !important;
    overflow: auto !important;
}

.header_category_name {
    display: flex;
    align-items: center;
}

.category_name label {
    font-size: 18px !important;
}

.blocks_list {
    display: flex;
    align-items: center;
    margin-bottom: 10px;;
}

.blocks_list>div {
    margin-right: 15px !important;
}