
#EditorBlocklyContainer{
	width: 85%;
	height: 100%;
	padding-top: 20px;
}



.ui.grid>.row.EditorRow{
	padding: 2;
	margin:0;
}

#EditorGrid{
		height: 100%;
		display: flex;
  		flex-flow: column;
}


#freeCreationGrid{
		height: 100%;
		display: flex;
  		flex-flow: column;
}

