.ui.items>.item.CourseSummaryItem{
	border-radius: 10px;
	border: 1px solid rgba(34,36,38,.15);
	padding:8px;
}
.ui.card .meta.CourseSummaryDescription {
	color: rgba(0,0,0,.87) !important;
	margin-top: 10px;
}
.CourseSummaryResetButton{
	color: rgb(172, 72, 72);
	cursor: pointer;
	display: inline-block;
	margin: 6px 0;
}