.StudentProfilOnlineDot{
	height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background-color: #42b72a;
}

.StudentProfilOnlineDotAnimate{
	animation: onlineDotPulse 0.5s linear;
	animation-iteration-count: 2;
}

.StudentProfilOnlineDot-inner-dot{
	position: relative;
	top:0;
	height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background-color: #42b72a;
  animation: 0
}



@keyframes onlineDotPulse{
  0% {
    transform: scale(1);
    opacity: .75;
  }
  25% {
    transform:scale(1.5);
    opacity:.50;
  }
  50% {
    transform:scale(1.7);
    opacity:0.25
  }
  75% {
    transform:scale(1.5);
    opacity:0.50
  }
  100% {
    transform:scale(1);
    opacity:1
  }
}

.pane {
  padding: 1em;
  border-left: 1px solid rgba(34,36,38,.15);
  border-right: 1px solid rgba(34,36,38,.15);
  border-bottom: 1px solid rgba(34,36,38,.15);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.activityButtonContainer {
  margin-bottom: 25px;
}