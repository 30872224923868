.bandeau-container{
    min-height: 170px;
    background-image: url("../../img/banniere_simple_thingz_v9.svg");
    background-size: cover;
    background-position: 50% 50%; 
    background-repeat: no-repeat;
}

@media only screen and (max-width: 768px){
	.bandeau-container{
		background-position: 0% 50%; 
	}
}