#monitor{
	color:#33ff00;
	background-color: black;
	margin: 0;
	margin-top:5px; 
	scrollbar-color: light;
}

#monitor::-webkit-scrollbar{
	background-color: white;
}

#serialClose i{
	margin-left:0px;
}