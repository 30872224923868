.container-fluid{
    padding-left: 0;
}

.generalMessage{
	margin-bottom: 50px;
	margin-top: 5px;
}

#CourseBlocklyComponent{
	width: 100%;
	height: 100%;
}

#courseMainDiv{
	/*height: 100%;*/
	display: flex;
	flex-direction: column;
	flex: 1;
}

#courseGird{
	/* height: 100%; */
	display: flex;
  flex-flow: column;
  flex:1;
  margin:0;
  padding: 0;
}

.HeaderRow {
	padding-bottom: 0px!important;
}

.WorkspaceRow {
	/* padding: 0px!important; */
	padding-top: 0px!important;
	padding-bottom: 0px!important;
}