/* STYLES GENERAUX  */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* position: relative; */
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

img,
iframe,
video {
    max-width: 100%;
}

li {
    list-style: none;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.message-transition-container {
    display: flex;
    justify-content: space-between;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;

    border: 1px solid #D3D3D3;
    border-radius: 10px;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: white;
    z-index: 1080;

    opacity: 0;
    transition: 500ms opacity ease-in;
}

.message-transition-avatar {
    background: url("../src/img/miss.svg") no-repeat center;
    background-size: contain;

    width: 80px;
    height: 80px;
}

.message-transition-congrats {
    z-index: 6000;
    padding-top: 4px;
    margin-left: 15px;
}

.message-transition-congrats-author{
    font-size: 18px;
    padding-bottom: 10px;
    color: black;
    font-weight: 700;
}

.message-transition-congrats-text{
    font-size: 25px;
    color: black;
}

