#HeaderFreeCreationMenu{
	margin: 0px;
	border: none;
}

.Menu{
    margin: 0px!important;
    border: none!important;
}

.logo{
	padding-top: 0px!important;
    padding-bottom: 0px!important;
    padding-right: 1.5em!important;
}

.innerColumns{
    margin-top: auto;
    margin-bottom: auto;
}

.ProgressParagraph{
    text-align: center;
    margin-bottom: 0px!important;
}

.ProgressBar{
    margin-bottom: 0px!important;
}